import React from 'react';
import { getImageSrc, isSafeUrl, checkTypeIsFile, checkContainsJS, stripScript } from '../../utils';
import HoverImg from './helpers/HoverImg';
import {
    Toggle,
    ToggleLink,
    Button,
    Box,
    LabeledInput,
    LabeledSelect,
    Textarea,
    LabeledTextarea,
    Row,
    Col,
    H5,
    Dropzone,
} from '@commonsku/styles';

const IMG_SIZE = {
    w: 260,
    h: 160,
};

export const HomePageCard = ({
    onUploadImg,
    onDeleteFile,
    onUpdate,
    onDelete,
    findAudience,
    loading,
    display_loading,
    audiences,
    edit_title,
    marketing_setting_id,
    file_data,
    title,
    link,
    audience,
    value,
    type='img',
    active,
    error,
    click_count=0,
}) => {
    function handleKeyDown(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    function _createEventHandler(property, remoteUpdate=false, options={}) {
        return e => {
            let val = '';
            let err = '';
            if (options && options.val) {
                if (typeof options.val == 'function') {
                    val = options.val();
                } else {
                    val = options.val;
                }
            } else if (options && options.type) {
                if (options.type == 'select') {
                    val = e.value;
                }
            } else if (options && options.isFile) {
                val = e.target && e.target.files
                    ? e.target.files[0].name
                    : (e.target  ? e.target.value : e);
            } else {
                val = e.target ? e.target.value : (e.value || e);
            }

            let payload = {};
            if (options) {
                if (options.checks) {
                    if (options.checks(val) === false) {
                        err = options.errorMsg || 'Invalid value';
                    }
                }

                if (options.filterVal) {
                    val = options.filterVal(val);
                }
            }

            if (err) {
                payload = { error: options.errorMsg || 'Invalid value' };
            } else {
                payload = { [property]: val, };
            }

            onUpdate({
                ...payload,
                update_remote: remoteUpdate,
                ...(options.additionalState ? options.additionalState : {})
            });
        };
    }

    const _onChangeTitle = _createEventHandler('title', false, {
        additionalState: { edit_title: true }
    });
    const _onChangeLink = _createEventHandler('link', false);
    const _onChangeContent = _createEventHandler('content', false, {
        isFile: checkTypeIsFile(type)
    });

    const _onUpdateAudience = _createEventHandler('audience', true, {
        type: 'select',
    });
    const _onUpdateTitle = _createEventHandler('title', true, {
        additionalState: { edit_title: false },
        checks: checkContainsJS,
        filterVal: stripScript,
    });
    const _onUpdateLink = _createEventHandler('link', true, {
        checks: (link) => isSafeUrl(link),
        errorMsg: 'Url must start with http:// or https://',
    });
    const _onUpdateContent = _createEventHandler('content', true, {
        isFile: checkTypeIsFile(type)
    });
    const _onUpdateActive = _createEventHandler('active', true, {
        val: () => active == 1 ? 0 : 1,
        checks: (val) => {
            if (val == 0 || file_data) { return true; }
            return false;
        },
        errorMsg: 'Upload an image in order to activate it.'
    });

    const isActive = active == 1;

    return (
        <Box padded controls= {
            <Button cta size={'small'} onClick={onDelete}>Delete</Button>
        }>
            {error ? <Row><Col padded xs>
                <p style={{
                    textAlign: 'center',
                    color: 'red',
                    fontSize: '1.5em',
                    fontWeight: 'bold',
                    margin: 0,
                    marginBottom: 10,
                }}>{error}</p>
            </Col></Row> : null}
            <Row>
                <Col padded xs>
                    <div>
                        <LabeledInput
                            label="Link"
                            value={link}
                            onChange={_onChangeLink}
                            onBlur={_onUpdateLink}
                            onFocus={(e) => e.target.select()}
                        />
                    </div>
                    <div>
                        <LabeledSelect
                            label="Audience"
                            value={findAudience(audience)}
                            options={audiences}
                            onChange={_onUpdateAudience}
                            onFocus={(e) => e.target.select()}
                        />
                    </div>

                    <Toggle style={{width: 110}} stretch={false}>
                        <ToggleLink
                            selected={isActive}
                            onClick={_onUpdateActive}
                        >Active</ToggleLink>
                        <ToggleLink
                            selected={!isActive}
                            onClick={_onUpdateActive}
                        >Disabled</ToggleLink>
                    </Toggle>

                    <div>
                        <H5>Total Clicks: {click_count || 0}</H5>
                    </div>
                </Col>
                <Col padded xs>
                    {checkTypeIsFile(type)
                        ? <div>
                            <Dropzone
                                onDrop={onUploadImg(marketing_setting_id)} multiple={false}
                                accept="image/*"
                                style={{
                                    width: IMG_SIZE.w,
                                    height: file_data && file_data.file_id ? 'initial' : IMG_SIZE.h,
                                    maxHeight: IMG_SIZE.h,
                                    borderWidth: 2,
                                    borderColor: 'rgb(102, 102, 102)',
                                    borderStyle: file_data ? '' : 'dashed',
                                    borderRadius: 5,
                                    textAlign: 'center',
                                }}
                            >
                                <>{loading || display_loading
                                    ? <div className="small-12 text-center"><br /><img src="/images/gears.gif" /><br /></div>
                                    : file_data
                                        ? <HoverImg
                                            src={getImageSrc(file_data, 'original')}
                                            btnOnClick={(e) => onDeleteFile(file_data.file_id)}
                                            style={{maxHeight: IMG_SIZE.h, }}
                                            width={IMG_SIZE.w}
                                            target="_blank"
                                        />
                                        : <div style={{textAlign: 'center', }}>
                                            Upload Image
                                            <span style={{color: '#A4ABAE',}}>
                                                <br/><span style={{fontWeight: 'bold'}}>Image Dimensions</span>
                                                <br/>Width: {IMG_SIZE.w}px, Height: {IMG_SIZE.h}px
                                            </span>
                                        </div>
                                }</>
                            </Dropzone>
                            <Textarea
                                style={{
                                    marginTop: 5,
                                    fontSize: 15,
                                    padding: 3,
                                    lineHeight: 'initial',
                                    resize: 'vertical',
                                    width: IMG_SIZE.w+5,
                                    ...(!edit_title && file_data && file_data.file_id ? {
                                        padding: 0,
                                        lineHeight: 'initial',
                                        color: '#5ba0b4',
                                        border: 0,
                                        boxShadow: 'none',
                                        fontWeight: 'bold',
                                    } : {}),
                                }}
                                placeholder="Caption"
                                value={title}
                                onChange={_onChangeTitle}
                                onBlur={_onUpdateTitle}
                                // onClick={() => {onUpdate({edit_title: true})}}
                                onKeyDown={handleKeyDown}
                                onFocus={(e) => e.target.select()}
                                rows="4"
                            />
                        </div>
                        : <div>
                            <LabeledTextarea
                                label='Enter HTML'
                                value={value}
                                onChange={_onChangeContent}
                                onBlur={_onUpdateContent}
                                onFocus={(e) => e.target.select()}
                            />
                        </div>
                    }
                </Col>
            </Row>
        </Box>
    );

};
