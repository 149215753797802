import React, { useState } from 'react';
import Base_Img from '../../Img';
import { Button } from '@commonsku/styles';

export function HoverImg({
    src,
    hoverExtend=true,
    btnOnClick,
    ...props
}) {
    const [ isHoveringImg, updateHoverImg ] = useState(false);

    return (
        <div style={{position: 'relative', textAlign: 'center', color: 'white',}}>
            <Base_Img
                src={src}
                target="_blank"
                style={{width: '100%', }}
                onMouseOver={(e) => updateHoverImg(true)}
                onMouseOut={(e) => updateHoverImg(false)}
                {...props}
            />
            <div
                style={{position: 'absolute', top: 12, right: 8, fontSize: '1.3em',
                    visibility: hoverExtend && isHoveringImg ? 'visible' : 'hidden',
                }}
                onMouseOver={(e) => updateHoverImg(true)}
                onMouseOut={(e) => updateHoverImg(false)}
            >
                <Button cta size={'small'} onClick={btnOnClick}>&#x2715;</Button>
            </div>
        </div>
    );
}

export default HoverImg;
