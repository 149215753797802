import React from 'react';
import { getImageSrc } from '../../utils';
import { Box, Input, Button, Spinner, Row, Col, Dropzone, } from '@commonsku/styles';

const IMG_SIZE = {
    w: 555,
    h: 300,
};

export const LoginPageSettings = ({
    loading,
    setting_name,
    settings,
    addSetting,
    updateSetting,
    onUploadImg,
}) => {
    function getSetting() {
        let s = null;
        if (settings && settings.data && Array.isArray(settings.data) && settings.data.length > 0) {
            s = settings.data[0];
        }
        return s;
    }

    function renderImgPreview(imgUrl) {
        return (
            <div className="login_crd" style={{
                background: `url('${imgUrl}') no-repeat center center`,
                backgroundSize: 'cover',
            }}>
                <img src="/images/xtrnl.svg" />
            </div>
        );
    }

    const s = getSetting();

    return (
        loading ? <Spinner /> : s && s.marketing_setting_id ? <Row>
            <Col padded xs>
                <Box key={'loginpage_card_0_' + s.marketing_setting_id} style={{padding: 20}}>
                    {s.error ? <Row><Col padded xs>
                        <p style={{
                            textAlign: 'center',
                            color: 'red',
                            fontSize: '1.5em',
                            fontWeight: 'bold',
                            margin: 0,
                            marginBottom: 10,
                        }}>{s.error}</p>
                    </Col></Row> : null}

                    <div style={{width: IMG_SIZE.w, height: IMG_SIZE.h+50, marginLeft: 'auto', marginRight: 'auto', marginBottom: 20, }}>
                        <Dropzone
                            onDrop={onUploadImg(s.marketing_setting_id)}
                            multiple={false}
                            accept="image/*"
                            style={{
                                width: IMG_SIZE.w,
                                height: s.file_data && s.file_data.file_id ? 'initial' : IMG_SIZE.h,
                                maxHeight: IMG_SIZE.h,
                                borderWidth: 2,
                                borderColor: 'rgb(102, 102, 102)',
                                borderStyle: s.file_data ? '' : 'dashed',
                                borderRadius: 5,
                                textAlign: 'center',
                                marginBottom: 20,
                            }}
                        >
                            <>{loading
                                ? <div className="small-12 text-center"><br /><img src="/images/gears.gif" /><br /></div>
                                : s.file_data
                                    ? renderImgPreview(getImageSrc(s.file_data, 'original'))
                                    : 'Upload Image'
                            }</>
                        </Dropzone>
                        <Input
                            placeholder="URL"
                            value={s.link}
                            onChange={(e) => {
                                updateSetting(s.marketing_setting_id, {
                                    link: e.target.value,
                                    active: 1,
                                    update_remote: false,
                                });
                            }}
                            onBlur={(e) => {
                                updateSetting(s.marketing_setting_id, {
                                    link: e.target.value,
                                    active: 1,
                                });
                            }}
                        />
                    </div>
                    <div style={{color: '#A4ABAE', textAlign: 'center', }}>
                        <span style={{fontWeight: 'bold'}}>Image Dimensions</span>
                        <br/>Width: {IMG_SIZE.w}, Height: {IMG_SIZE.h}
                    </div>
                </Box>
            </Col>
        </Row>
        : <Row className="mt-20">
            <Col padded xs>
                <Button style={{textAlign: 'center', }} size={'small'} onClick={(e) => {
                    if (setting_name) {
                        addSetting({ setting_name: setting_name });
                    }
                }}>+ New</Button>
            </Col>
        </Row>
    );
};

