import { oauth, unflatten } from '../utils';

export const LOAD_MARKETING_SETTINGS_REQUEST = 'LOAD_MARKETING_SETTINGS_REQUEST';
export const LOAD_MARKETING_SETTINGS_SUCCESS = 'LOAD_MARKETING_SETTINGS_SUCCESS';
export const LOAD_MARKETING_SETTINGS_FAILURE = 'LOAD_MARKETING_SETTINGS_FAILURE';

export const ADD_MARKETING_SETTING_REQUEST = 'ADD_MARKETING_SETTING_REQUEST';
export const ADD_MARKETING_SETTING_SUCCESS = 'ADD_MARKETING_SETTING_SUCCESS';
export const ADD_MARKETING_SETTING_FAILURE = 'ADD_MARKETING_SETTING_FAILURE';

export const UPDATE_MARKETING_SETTING_STATE = 'UPDATE_MARKETING_SETTING_STATE';
export const UPDATE_MARKETING_SETTING_REQUEST = 'UPDATE_MARKETING_SETTING_REQUEST';
export const UPDATE_MARKETING_SETTING_SUCCESS = 'UPDATE_MARKETING_SETTING_SUCCESS';
export const UPDATE_MARKETING_SETTING_FAILURE = 'UPDATE_MARKETING_SETTING_FAILURE';

export const DELETE_MARKETING_SETTING_REQUEST = 'DELETE_MARKETING_SETTING_REQUEST';
export const DELETE_MARKETING_SETTING_SUCCESS = 'DELETE_MARKETING_SETTING_SUCCESS';
export const DELETE_MARKETING_SETTING_FAILURE = 'DELETE_MARKETING_SETTING_FAILURE';

//
export const UPDATE_USER_POPUP_SETTING_STATE = 'UPDATE_USER_POPUP_SETTING_STATE';
export const UPDATE_USER_POPUP_SETTING_REQUEST = 'UPDATE_USER_POPUP_SETTING_REQUEST';
export const UPDATE_USER_POPUP_SETTING_SUCCESS = 'UPDATE_USER_POPUP_SETTING_SUCCESS';
export const UPDATE_USER_POPUP_SETTING_FAILURE = 'UPDATE_USER_POPUP_SETTING_FAILURE';

// Whats new Posts
export const LOAD_WHATSNEW_POSTS_REQUEST = 'LOAD_WHATSNEW_POSTS_REQUEST';
export const LOAD_WHATSNEW_POSTS_SUCCESS = 'LOAD_WHATSNEW_POSTS_SUCCESS';
export const LOAD_WHATSNEW_POSTS_FAILURE = 'LOAD_WHATSNEW_POSTS_FAILURE';

export const ADD_WHATSNEW_POST_REQUEST = 'ADD_WHATSNEW_POST_REQUEST';
export const ADD_WHATSNEW_POST_SUCCESS = 'ADD_WHATSNEW_POST_SUCCESS';
export const ADD_WHATSNEW_POST_FAILURE = 'ADD_WHATSNEW_POST_FAILURE';

export const UPDATE_WHATSNEW_POST_STATE = 'UPDATE_WHATSNEW_POST_STATE';
export const UPDATE_WHATSNEW_POST_REQUEST = 'UPDATE_WHATSNEW_POST_REQUEST';
export const UPDATE_WHATSNEW_POST_SUCCESS = 'UPDATE_WHATSNEW_POST_SUCCESS';
export const UPDATE_WHATSNEW_POST_FAILURE = 'UPDATE_WHATSNEW_POST_FAILURE';

export const DELETE_WHATSNEW_POST_REQUEST = 'DELETE_WHATSNEW_POST_REQUEST';
export const DELETE_WHATSNEW_POST_SUCCESS = 'DELETE_WHATSNEW_POST_SUCCESS';
export const DELETE_WHATSNEW_POST_FAILURE = 'DELETE_WHATSNEW_POST_FAILURE';

/***************************
 * Marketing Settings
 ***************************/

// ===========
// LOAD
// ===========
export const createLoadMarketingSettingsRequest = (value) => {
    return {
        type: LOAD_MARKETING_SETTINGS_REQUEST,
        payload: {
            value
        }
    };
};

export const createErrorLoadMarketingSettings = (error) => {
    return {
        type: LOAD_MARKETING_SETTINGS_FAILURE,
        payload: {
            error
        }
    };
};

export const createFinalizedLoadMarketingSettings = (value) => {
    return {
        type: LOAD_MARKETING_SETTINGS_SUCCESS,
        payload: {
            value
        }
    };
};

export const createLoadMarketingSettings = ({setting_name, ...data}) => dispatch => {
    dispatch(createLoadMarketingSettingsRequest(data));

    let params = {
        setting_name: setting_name,
        ...data
    };
    return oauth('GET', 'marketing-setting/get', params).then((resp) => {
        return dispatch(createFinalizedLoadMarketingSettings(resp.json.settings));
    }).catch(err => {
        // console.info(err);
        return dispatch(createErrorLoadMarketingSettings('Cannot Load Settings.'));
    });
};

// ===========
// ADD
// ===========
export const createAddMarketingSettingRequest = (value) => {
    return {
        type: ADD_MARKETING_SETTING_REQUEST,
        payload: {
            value
        }
    };
};

export const createErrorAddMarketingSetting = (error) => {
    return {
        type: ADD_MARKETING_SETTING_FAILURE,
        payload: {
            error
        }
    };
};

export const createFinalizedAddMarketingSetting = (value) => {
    return {
        type: ADD_MARKETING_SETTING_SUCCESS,
        payload: {
            value
        }
    };
};

export const createAddMarketingSetting = ({setting_name, value, audience, add_to_top=false, ...data}) => dispatch => {
    dispatch(createAddMarketingSettingRequest(data));

    let params = {
        setting_name,
        value,
        audience,
        ...data
    };
    return oauth('POST', 'marketing-setting', params).then(({ json }) => {
        return dispatch(createFinalizedAddMarketingSetting({...json.setting, add_to_top, edit_title: false}));
    }).catch(err => {
        // console.info(err);
        return dispatch(createErrorAddMarketingSetting('Cannot add Setting.'));
    });
};

// ===========
// UPDATE
// ===========
// Update just the state
export const createUpdateMarketingSettingState = (setting_id, value) => {
    return {
        type: UPDATE_MARKETING_SETTING_STATE,
        payload: {
            setting_id,
            value
        }
    };
};

export const createUpdateMarketingSettingRequest = (setting_id, value) => {
    return {
        type: UPDATE_MARKETING_SETTING_REQUEST,
        payload: {
            setting_id,
            value
        }
    };
};

export const createErrorUpdateMarketingSetting = (setting_id, error) => {
    return {
        type: UPDATE_MARKETING_SETTING_FAILURE,
        payload: {
            setting_id,
            error
        }
    };
};

export const createFinalizedUpdateMarketingSetting = (setting_id, value) => {
    return {
        type: UPDATE_MARKETING_SETTING_SUCCESS,
        payload: {
            setting_id,
            value
        }
    };
};

export const createUpdateMarketingSetting = (setting_id, {update_remote=true, ...data}) => dispatch => {
    if (data.error) {
        return dispatch(createErrorUpdateMarketingSetting(setting_id, data.error));
    }

    dispatch(createUpdateMarketingSettingRequest(setting_id, data));
    if (!update_remote) {
        return dispatch(createFinalizedUpdateMarketingSetting(setting_id, data));
    }

    let params = {
        id: setting_id,
        ...data
    };
    return oauth('PUT', 'marketing-setting', params).then(({ json }) => {
        return dispatch(createFinalizedUpdateMarketingSetting(setting_id, {...json.settings, edit_title: data.edit_title}));
    }).catch(err => {
        // console.info(err);
        return dispatch(createErrorUpdateMarketingSetting(setting_id, 'Cannot update Setting.'));
    });
};

// ===========
// DELETE
// ===========
export const createDeleteMarketingSettingRequest = (setting_id) => {
    return {
        type: DELETE_MARKETING_SETTING_REQUEST,
        payload: {
            setting_id
        }
    };
};

export const createErrorDeleteMarketingSetting = (setting_id, error) => {
    return {
        type: DELETE_MARKETING_SETTING_FAILURE,
        payload: {
            setting_id,
            error
        }
    };
};

export const createFinalizedDeleteMarketingSetting = (setting_id) => {
    return {
        type: DELETE_MARKETING_SETTING_SUCCESS,
        payload: {
            setting_id
        }
    };
};

export const createDeleteMarketingSetting = (setting_id) => dispatch => {
    dispatch(createDeleteMarketingSettingRequest(setting_id));

    let params = {id: setting_id,};
    return oauth('DELETE', 'marketing-setting', params).then(({ json }) => {
        return dispatch(createFinalizedDeleteMarketingSetting(setting_id));
    }).catch(err => {
        // console.info(err);
        return dispatch(createErrorDeleteMarketingSetting(setting_id, 'Cannot delete Setting.'));
    });
};

// **************************
// User Popup
// **************************
// ===========
// UPDATE
// ===========
export const createUpdateUserPopupSettingState = (setting_name, value) => {
    return {
        type: UPDATE_USER_POPUP_SETTING_STATE,
        payload: {
            setting_name,
            value
        }
    };
};

export const createUpdateUserPopupSettingRequest = (setting_name, value) => {
    return {
        type: UPDATE_USER_POPUP_SETTING_REQUEST,
        payload: {
            setting_name,
            value
        }
    };
};

export const createErrorUpdateUserPopupSetting = (setting_name, error) => {
    return {
        type: UPDATE_USER_POPUP_SETTING_FAILURE,
        payload: {
            setting_name,
            error
        }
    };
};

export const createFinalizedUpdateUserPopupSetting = (setting_name, value) => {
    return {
        type: UPDATE_USER_POPUP_SETTING_SUCCESS,
        payload: {
            setting_name,
            value
        }
    };
};

export const createUpdateUserPopupSetting = (setting_name, {update_remote=true, active=0, content=''}) => dispatch => {
    dispatch(createUpdateUserPopupSettingRequest(setting_name, {active, content}));
    if (!update_remote) {
        return dispatch(createFinalizedUpdateUserPopupSetting(setting_name, {active, content}));
    }

    let params = {
        company_type: setting_name.split('-')[0],
        activate_popup: active==1 ? 'enable' : 'disable',
        popup_html: content
    };
    return oauth('POST', 'user', params).then(({ json }) => {
        return dispatch(createFinalizedUpdateUserPopupSetting(setting_name, {active, content}));
    }).catch(err => {
        // console.info(err);
        return dispatch(createErrorUpdateUserPopupSetting(setting_name, 'Cannot update Setting.'));
    });
};

/***************************
 * Whats New posts
 ***************************/

// ===========
// LOAD
// ===========
export const createLoadWhatsnewPostsRequest = (value) => {
    return {
        type: LOAD_WHATSNEW_POSTS_REQUEST,
        payload: {
            value
        }
    };
};

export const createErrorLoadWhatsnewPosts = (error) => {
    return {
        type: LOAD_WHATSNEW_POSTS_FAILURE,
        payload: {
            error
        }
    };
};

export const createFinalizedLoadWhatsnewPosts = (value) => {
    return {
        type: LOAD_WHATSNEW_POSTS_SUCCESS,
        payload: {
            value
        }
    };
};

export const createLoadWhatsnewPosts = (data={audience: ''}) => dispatch => {
    dispatch(createLoadWhatsnewPostsRequest(data));

    let params = data;
    return oauth('GET', 'whatsnew-post/get', params).then((resp) => {
        return dispatch(createFinalizedLoadWhatsnewPosts(
            unflatten(resp.json.posts, 'whatsnew_post_id', 'parent_id')
        ));
    }).catch(err => {
        // console.info(err);
        return dispatch(createErrorLoadWhatsnewPosts('Cannot Load Posts.'));
    });
};

// ===========
// ADD
// ===========
export const createAddWhatsnewPostRequest = (value) => {
    return {
        type: ADD_WHATSNEW_POST_REQUEST,
        payload: {
            value
        }
    };
};

export const createErrorAddWhatsnewPost = (error) => {
    return {
        type: ADD_WHATSNEW_POST_FAILURE,
        payload: {
            error
        }
    };
};

export const createFinalizedAddWhatsnewPost = (value) => {
    return {
        type: ADD_WHATSNEW_POST_SUCCESS,
        payload: {
            value
        }
    };
};

export const createAddWhatsnewPost = ({title, content, audience, content_type='text', ...data}) => dispatch => {
    dispatch(createAddWhatsnewPostRequest(data));

    let params = {
        title,
        content,
        audience,
        content_type,
        ...data
    };
    return oauth('POST', 'whatsnew-post', params).then(({ json }) => {
        return dispatch(createFinalizedAddWhatsnewPost(json.post));
    }).catch(err => {
        console.info(err);
        return dispatch(createErrorAddWhatsnewPost('Cannot add Post.'));
    });
};

// ===========
// UPDATE
// ===========
// Update just the state
export const createUpdateWhatsnewPostState = (whatsnew_post_id, value) => {
    return {
        type: UPDATE_WHATSNEW_POST_STATE,
        payload: {
            whatsnew_post_id,
            value
        }
    };
};

export const createUpdateWhatsnewPostRequest = (whatsnew_post_id, value) => {
    return {
        type: UPDATE_WHATSNEW_POST_REQUEST,
        payload: {
            whatsnew_post_id,
            value
        }
    };
};

export const createErrorUpdateWhatsnewPost = (whatsnew_post_id, error, value) => {
    return {
        type: UPDATE_WHATSNEW_POST_FAILURE,
        payload: {
            whatsnew_post_id,
            value,
            error
        }
    };
};

export const createFinalizedUpdateWhatsnewPost = (whatsnew_post_id, value) => {
    return {
        type: UPDATE_WHATSNEW_POST_SUCCESS,
        payload: {
            whatsnew_post_id,
            value
        }
    };
};

export const createUpdateWhatsnewPost = (whatsnew_post_id, {update_remote=true, ...data}) => dispatch => {
    if (data.error) {
        return dispatch(createErrorUpdateWhatsnewPost(whatsnew_post_id, data.error, data));
    }

    dispatch(createUpdateWhatsnewPostRequest(whatsnew_post_id, data));
    if (!update_remote) {
        return dispatch(createFinalizedUpdateWhatsnewPost(whatsnew_post_id, data));
    }

    let params = {
        id: whatsnew_post_id,
        ...data
    };
    return oauth('PUT', 'whatsnew-post', params).then(({ json }) => {
        return dispatch(createFinalizedUpdateWhatsnewPost(whatsnew_post_id, json.post));
    }).catch(err => {
        console.info(err);
        return dispatch(createErrorUpdateWhatsnewPost(whatsnew_post_id, 'Cannot update post.', data));
    });
};

// ===========
// DELETE
// ===========
export const createDeleteWhatsnewPostRequest = (whatsnew_post_id, value) => {
    return {
        type: DELETE_WHATSNEW_POST_REQUEST,
        payload: {
            whatsnew_post_id,
            value
        }
    };
};

export const createErrorDeleteWhatsnewPost = (whatsnew_post_id, error) => {
    return {
        type: DELETE_WHATSNEW_POST_FAILURE,
        payload: {
            whatsnew_post_id,
            error
        }
    };
};

export const createFinalizedDeleteWhatsnewPost = (whatsnew_post_id, value) => {
    return {
        type: DELETE_WHATSNEW_POST_SUCCESS,
        payload: {
            whatsnew_post_id,
            value
        }
    };
};

export const createDeleteWhatsnewPost = (whatsnew_post_id, data={}) => dispatch => {
    dispatch(createDeleteWhatsnewPostRequest(whatsnew_post_id));

    let params = {id: whatsnew_post_id,};
    return oauth('DELETE', 'whatsnew-post', params).then(({ json }) => {
        return dispatch(createFinalizedDeleteWhatsnewPost(whatsnew_post_id, data));
    }).catch(err => {
        // console.info(err);
        return dispatch(createErrorDeleteWhatsnewPost(whatsnew_post_id, 'Cannot delete post.'));
    });
};

export const createDeleteWhatsnewPostRelatedFiles = (id, file_id) => dispatch => {
    if (!id || !file_id) { return; }

    let params = {id: id, not_file_id: file_id, rel_files: true};
    return oauth('DELETE', 'whatsnew-post', params).then(({ json }) => {
        // return dispatch(createFinalizedDeleteWhatsnewPost(whatsnew_post_id, data));
    }).catch(err => {
        console.info(err);
        // return dispatch(createErrorDeleteWhatsnewPost(whatsnew_post_id, 'Cannot delete post.'))
    });
};

export const createDeleteMarketingSettingRelatedFiles = (id, file_id) => dispatch => {
    if (!id || !file_id) { return; }

    let params = {id: id, not_file_id: file_id, rel_files: true};
    return oauth('DELETE', 'marketing-setting', params).then(({ json }) => {
        // return dispatch(createFinalizedDeleteWhatsnewPost(whatsnew_post_id, data));
    }).catch(err => {
        console.info(err);
        // return dispatch(createErrorDeleteWhatsnewPost(whatsnew_post_id, 'Cannot delete post.'))
    });
};

