import React from 'react';
import { HomePageCard } from './HomePageCard';
import { Button, Spinner, Box, Select, Row, Col, LabeledRadio, ButtonsGroup } from '@commonsku/styles';
import { useFilterAudience } from './hooks';

export const HomePageSettings = ({
    loading,
    audiences,
    settings,
    setting_name='HOMEPAGE-AD',
    addSetting,
    deleteSetting,
    updateSetting,
    onUploadImg,
    onDeleteFile,
    findAudience,
}) => {
    const data = (settings && Array.isArray(settings.data) && settings.data) || [];
    const [ setActive, setAudience, audience, active, filteredData ] = useFilterAudience(data, {active: -1, audience: 'ALL'});

    function _createEventHandler(stateFunc, val=null) {
        return e => {
            stateFunc(val !== null && val !== undefined ? val : e.value); // using e.value because also using it with <select>
        };
    }

    return (
        loading ? <Spinner /> : <div>
            <Box padded title={'Homepage Settings'} controls={
                <Button size={'small'} onClick={(e) => {
                    if (setting_name) {
                        addSetting({ setting_name: setting_name, active: 0, audience: 'ALL', add_to_top: true });
                        setActive(-1);
                    }
                }}>New</Button>
            }>
                <Row>
                    <Col padded xs>
                        <Select noMargin
                            label="Audience"
                            value={findAudience(audience)}
                            options={audiences}
                            onChange={_createEventHandler(setAudience)}
                            onFocus={(e) => e.target.select()}
                        />
                    </Col>
                    <Col padded xs style={{alignSelf: 'flex-end',}}>
                        <ButtonsGroup>
                            <LabeledRadio
                                label="Active"
                                checked={active == 1}
                                onClick={_createEventHandler(setActive, 1)}
                            />
                            <LabeledRadio
                                label="Disabled"
                                checked={active == 0}
                                onClick={_createEventHandler(setActive, 0)}
                            />
                            <LabeledRadio
                                label="All"
                                checked={active == -1}
                                onClick={_createEventHandler(setActive, -1)}
                            />
                        </ButtonsGroup>
                    </Col>
                    <Col padded xs>
                        <h3>{filteredData && filteredData.length}</h3>
                    </Col>
                </Row>
            </Box>
            {filteredData && filteredData.map((v, i) =>
                <HomePageCard key={'block_' + i + '_' + v.marketing_setting_id} {...v}
                    audiences={audiences}
                    findAudience={findAudience}
                    onDelete={() => deleteSetting(v.marketing_setting_id)}
                    onUpdate={(data) => updateSetting(v.marketing_setting_id, data)}
                    onUploadImg={(data) => onUploadImg(v.marketing_setting_id, 'MARKETING-SETTING')}
                    onDeleteFile={(file_id) => onDeleteFile(v.marketing_setting_id, 'MARKETING-SETTING', file_id)}
                />
            )}
        </div>
    );
};
