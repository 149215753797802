import React, { useState } from 'react';
import { getImageSrc, isSafeUrl } from '../../utils';
import Img from '../Img';
import HoverImg from './helpers/HoverImg';
import { useFilterAudience } from './hooks';
import {
    Toggle,
    ToggleLink,
    Button,
    Box,
    LabeledInput,
    LabeledSelect,
    LabeledTextarea,
    Spinner,
    Row,
    Col,
    Select,
    ButtonsGroup,
    LabeledRadio,
    Dropzone,
} from '@commonsku/styles';

export const WhatsNewSettings = ({
    audiences,
    posts,
    loading,
    display_loading,
    onUploadImg,
    onDeleteFile,
    onAdd,
    onUpdate,
    onDelete,
    findAudience,
}) => {
    const data = (posts && posts.data && posts.data) || [];

    const [ canEdit, setCanEdit ] = useState(true);
    const [ setActive, setAudience, audience, active, filteredData ] = useFilterAudience(data, {active: -1, audience: 'TENANT'});

    function onEditDone(e) {
        setCanEdit(!canEdit);
        setActive(-1);
    }

    function _createEventHandler(stateFunc, val=null) {
        return e => {
            stateFunc(val !== null && val !== undefined ? val : e.value); // using e.value because also using it with <select>
        };
    }

    return (
        loading ? <Spinner /> :
            <div>
                <Box padded title="Whats New Posts" controls={
                    <React.Fragment>
                        <Button size={'small'} onClick={(e) => {
                            onAdd();
                            setActive(-1);
                        }}>New</Button>
                        <Button className="ml-10" secondary={!canEdit} cta={canEdit} size={'small'} onClick={onEditDone}>{canEdit ? 'Preview' : 'Edit'}</Button>
                    </React.Fragment>
                }>
                    <Row>
                        <Col padded xs>
                            <Select noMargin
                                label="Audience"
                                value={findAudience(audience)}
                                options={audiences}
                                onChange={_createEventHandler(setAudience)}
                                onFocus={(e) => e.target.select()}
                            />
                        </Col>
                        <Col padded xs style={{alignSelf: 'flex-end',}}>
                            <ButtonsGroup>
                                <LabeledRadio
                                    label="Active"
                                    checked={active == 1}
                                    onClick={_createEventHandler(setActive, 1)}
                                />
                                <LabeledRadio
                                    label="Disabled"
                                    checked={active == 0}
                                    onClick={_createEventHandler(setActive, 0)}
                                />
                                <LabeledRadio
                                    label="All"
                                    checked={active == -1}
                                    onClick={_createEventHandler(setActive, -1)}
                                />
                            </ButtonsGroup>
                        </Col>
                        <Col padded xs>
                            <h3>{filteredData && filteredData.length}</h3>
                        </Col>
                    </Row>
                </Box>
                {filteredData && filteredData.map((v, i) =>
                    <Card key={'whatsnew_post_' + i + '_' + v.whatsnew_post_id} {...v}
                        audiences={audiences}
                        onDelete={() => onDelete(v.whatsnew_post_id)}
                        onUpdate={(data) => onUpdate(v.whatsnew_post_id, data)}
                        onUploadImg={(id, data={}) => onUploadImg(id || v.whatsnew_post_id, 'WHATSNEW-POST', data)}
                        onDeleteFile={(id, file_id) => onDeleteFile(id || v.whatsnew_post_id, 'WHATSNEW-POST', file_id)}
                        onAddChild={(data) => onAdd({parent_id: v.whatsnew_post_id, ...data})}
                        onUpdateChild={(id, data={}) => onUpdate(id, {parent_id: v.whatsnew_post_id, ...data})}
                        onDeleteChild={(id, data={}) => onDelete(id, {parent_id: v.whatsnew_post_id, ...data})}
                        canEdit={canEdit}
                        loading={loading}
                        display_loading={display_loading}
                        findAudience={findAudience}
                    />
                )}
            </div>
    );
};

/**
 * Card
 */
const Card = ({
    onUploadImg,
    onDeleteFile,
    onUpdate,
    onDelete,
    loading,
    display_loading,
    audiences,
    whatsnew_post_id,
    file_data,
    title,
    audience,
    content,
    content_type='text',
    active,
    children,
    onAddChild,
    onUpdateChild,
    onDeleteChild,
    error,
    display_date,
    canEdit,
    findAudience,
}) => {

    function _createEventHandler(property, remoteUpdate=false, options={}) {
        return e => {
            let val = '';
            if (options && options.val) {
                if (typeof options.val == 'function') {
                    val = options.val();
                } else {
                    val = options.val;
                }
            } else if (options && options.type) {
                if (options.type == 'select') {
                    val = e.value;
                }
            } else if (options && options.isFile) {
                val = e.target && e.target.files
                    ? e.target.files[0].name
                    : (e.target ? e.target.value : e);
            } else {
                val = e.target ? e.target.value : (e.value || e);
            }

            let payload = {};
            if (options && options.checks) {
                if (options.checks(val) === false) {
                    payload = { error: options.errorMsg || 'Invalid value' };
                } else {
                    payload = { [property]: val, };
                }
            } else {
                payload = { [property]: val, };
            }

            onUpdate({
                ...payload,
                update_remote: remoteUpdate,
                ...(options.additionalState ? options.additionalState : {})
            });
        };
    }

    function _childEventHandler(property, remoteUpdate=false, id, options={}) {
        return e => {
            const val = property === 'content_type' ? 'link-' + e.value : e.target ? e.target.value : (e.value || e);
            let payload = {};
            if (options && options.checks) {
                if (options.checks(val) === false) {
                    payload = { error: options.errorMsg || 'Invalid value' };
                } else {
                    payload = { [property]: val, };
                }
            } else {
                payload = { [property]: val, };
            }

            onUpdateChild(id, {
                ...payload,
                update_remote: remoteUpdate,
                active: 1,
            });
        };
    }

    function getLinksFromChildren() {
        if (children && Array.isArray(children) && children.length > 0) {
            let result = children.filter(v => v.content_type.indexOf('link') !== -1);
            return result;
        }
        return null;
    }

    function renderLinks(edit=true) {
        const links = getLinksFromChildren();

        return links && links.map((v, i) =>
            <ArticleLink
                edit={edit}
                key={'whatsnew_link_'+i+'_'+v.whatsnew_post_id}
                type={v.content_type.replace('link', '').replace('-', '')}
                title={v.title}
                link={v.content}
                onChangeTitle={_childEventHandler('title', false, v.whatsnew_post_id)}
                onChangeLink={_childEventHandler('content', false, v.whatsnew_post_id)}
                onUpdateTitle={_childEventHandler('title', true, v.whatsnew_post_id)}
                onUpdateLink={_childEventHandler('content', true, v.whatsnew_post_id, {
                    checks: (val) => isSafeUrl(val),
                    errorMsg: 'Url must start with http:// or https://',
                })}
                onUpdateLinkType={_childEventHandler('content_type', true, v.whatsnew_post_id)}
                onDelete={(e) => onDeleteChild(v.whatsnew_post_id)}
            />
        );
    }

    function renderPreview() {
        const d = new Date(display_date);
        return (
            <div className="wntile" style={{ width: '695px' }}>
                <h2>{title}</h2><div className="tprght">{d.toString().split(' ')[1]} {d.getFullYear()}</div>
                <div style={{whiteSpace: 'pre-line', }}>{content}</div>
                {file_data &&
                    <div className="wninfo">
                        <Img
                            src={getImageSrc(file_data, 'original')}
                            target="_blank"
                            style={{maxWidth: 295, }}
                        />
                    </div>}
                <p>{renderLinks(false)}</p>
            </div>
        );
    }

    if (!canEdit) {
        return renderPreview();
    }

    const isActive = active == 1;
    const _onChangeTitle = _createEventHandler('title', false);
    const _onChangeContent = _createEventHandler('content', false);
    const _onUpdateAudience = _createEventHandler('audience', true, { type: 'select', });
    const _onUpdateTitle = _createEventHandler('title', true);
    const _onUpdateContent = _createEventHandler('content', true);
    const _onUpdateActive = _createEventHandler('active', true, {
        val: () => isActive ? 0 : 1,
    });

    return (
        <Box title={title} padded controls={
            <Button cta size={'small'} onClick={onDelete}>Delete</Button>
        }>
            {error ? <Row><Col padded xs>
                <p style={{
                    textAlign: 'center',
                    color: 'red',
                    fontSize: '1.5em',
                    fontWeight: 'bold',
                    margin: 0,
                    marginBottom: 10,
                }}>{error}</p>
            </Col></Row> : null}
            <Row padded>
                <Col lg={8} md={8} padded>
                    <LabeledInput
                        label="Title"
                        value={title}
                        onChange={_onChangeTitle}
                        onBlur={_onUpdateTitle}
                    />
                    <LabeledSelect
                        label="Audience"
                        value={findAudience(audience)}
                        options={audiences}
                        onChange={_onUpdateAudience}
                    />
                    <LabeledTextarea
                        label="Content"
                        rows={10}
                        value={content}
                        onChange={_onChangeContent}
                        onBlur={_onUpdateContent}
                    />
                </Col>
                <Col md padded>
                    <Dropzone
                        onDrop={onUploadImg(whatsnew_post_id)}
                        multiple={false}
                        accept="image/*"
                        style={{
                            textAlign: 'center',
                            ...(!(file_data) ? {
                                borderWidth: 2,
                                borderRadius: 5,
                                borderColor: '#666666',
                                borderStyle: 'dashed',
                                height: 200,
                                maxWidth: 295,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            } : {}),
                        }}
                    >
                        <>{loading
                            ? <div className="small-12 text-center"><br /><img src="/images/gears.gif" /><br /></div>
                            : file_data
                                ? <HoverImg
                                    src={getImageSrc(file_data, 'original')}
                                    hoverExtend={canEdit}
                                    btnOnClick={(e) => onDeleteFile(whatsnew_post_id, file_data.file_id)}
                                />
                                : <div style={{textAlign: 'center', }}>
                                    Upload Image
                                    <span style={{color: '#A4ABAE',}}>
                                        <br/><span style={{fontWeight: 'bold'}}>Image Dimensions</span>
                                        <br/>Width: 295px, Height: 200px
                                    </span>
                                </div>
                        }</>
                    </Dropzone>
                </Col>
            </Row>
            <hr />
            <Row padded className="mt-20">
                <Col padded xs>{renderLinks(true)}</Col>
            </Row>
            <Row><Button size={'small'} onClick={(e) => onAddChild({
                active: 1,
                content_type: 'link-doc',
                title: 'How to Use',
                content: '',
            })}>Add Link</Button></Row>
            <hr />
            <Row>
                <Col padded xs>
                    <Toggle stretch={false}>
                        <ToggleLink
                            selected={isActive}
                            onClick={_onUpdateActive}
                        >Active</ToggleLink>
                        <ToggleLink
                            selected={!isActive}
                            onClick={_onUpdateActive}
                        >Disabled</ToggleLink>
                    </Toggle>
                </Col>
                <Col></Col>
            </Row>
        </Box>
    );
};

/**
 * Post Links
 */
const link_types = [{value: 'doc', label: 'Article/Document'}, {value: 'video', label: 'Video'}, {value: 'apple', label: 'Apple'}, {value: 'google', label: 'Google'}];
function getLinkType(type) { return link_types.filter(v => v.value == type)[0]; }
// Link Type => { 'doc', 'video' }
const ArticleLink = ({
    link,
    title,
    type='doc',
    edit=true,
    onChangeTitle,
    onChangeLink,
    onUpdateTitle,
    onUpdateLink,
    onUpdateLinkType,
    onDelete,
}) => {
    const [ isHovering, updateHover ] = useState(false);

    let styles = {
        opacity: .85,
        marginBottom: 10,
        color: '#fff',
        backgroundImage: `url(/images/whatsnew/${type=='video' ? 'video.png' : 'doc.png'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 20,
        backgroundPosition: '15px center',
        backgroundColor: '#5ca2b6',
        textDecoration: 'none',
        padding: '10px 20px 10px 40px',
        borderRadius: 20,
        display: 'inline-block',
        marginRight: 15,
        marginTop: 20,
    };

    if (edit) {
        return (<Row onMouseOver={(e) => updateHover(true)}
                     onMouseOut={(e) => updateHover(false)}>
            <Col padded xs={1} sm={1} md={1} lg={1} style={{alignSelf: 'center', maxWidth: '2%', }}>
                <a style={{cursor: 'pointer', visibility: isHovering ? 'visible' : 'hidden',}}
                   className="delete"
                   onClick={onDelete}
                >&times;</a>
            </Col>
            <Col padded xs>
                <LabeledInput
                    label="Title"
                    placeholder={type=='video' ? 'Video' : 'How to Use'}
                    value={title}
                    onChange={onChangeTitle}
                    onBlur={onUpdateTitle}
                />
            </Col>
            <Col padded xs>
                <LabeledInput
                    label="Link"
                    value={link}
                    onChange={onChangeLink}
                    onBlur={onUpdateLink}
                />
            </Col>
            <Col padded xs>
                <LabeledSelect
                    label="Link Type"
                    value={getLinkType(type)}
                    options={link_types}
                    onChange={onUpdateLinkType}
                />
            </Col>
        </Row>);
    }

    return (
        <a style={styles} href={link} target="_blank" rel="noopener noreferrer">{title || (type=='video' ? 'Video' : 'How to Use')}</a>
    );
};

