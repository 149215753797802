import React from 'react';
import { getImageSrc, isSafeUrl, checkTypeIsFile, checkContainsJS, stripScript } from '../../utils';
import Img from '../Img';
import {
  Toggle,
  ToggleLink,
  Button,
  Box,
  LabeledInput,
  LabeledSelect,
  Textarea,
  LabeledTextarea,
  Row,
  Col,
  H5,
  Spinner,
  Dropzone,
} from '@commonsku/styles';

const IMG_SIZE = {
  w: 260,
  h: 160,
};

const ERROR_STYLE = {
  textAlign: 'center',
  color: 'red',
  fontSize: '1.5em',
  fontWeight: 'bold',
  margin: 0,
  marginBottom: 10,
};

const getDropzoneStyle = (file_data) => ({
  width: IMG_SIZE.w,
  height: file_data?.file_id ? 'initial' : IMG_SIZE.h,
  maxHeight: IMG_SIZE.h,
  borderWidth: 2,
  borderColor: 'rgb(102, 102, 102)',
  borderStyle: file_data ? '' : 'dashed',
  borderRadius: 5,
  textAlign: 'center',
});

export const DASHBOARD_TILE_ADS = {
  'SALES-DASHBOARD-TILE-AD': 'Sales Dashboard Ad',
  'MANAGEMENT-DASHBOARD-TILE-AD': 'Management Dashbaord Ad',
  'PRODUCTION-DASHBOARD-TILE-AD': 'Production Dashboard Ad',
  'FINANCE-DASHBOARD-TILE-AD': 'Finance Dashboard Ad'
};

export const DashboardTileAdSettings = ({
  loading,
  settings,
  updateSetting,
  onUploadImg
}) => {
  const settings_data = (settings?.data ?? []).filter(
    s => Object.keys(DASHBOARD_TILE_ADS).includes(s.setting_name)
  ).reduce(
    (t, s) => ({
       ...t,
       [s.setting_name]: s
    }),
    {}
  );
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      {Object.keys(DASHBOARD_TILE_ADS).filter(d => settings_data[d]).map(
        d => (
          <DashboardTileAdSettingsCard
            key={d}
            label={DASHBOARD_TILE_ADS[d]}
            {...settings_data[d]}
            loading={loading}
            onUpdate={data => updateSetting(settings_data[d].marketing_setting_id, data)}         
            onUploadImg={onUploadImg(settings_data[d].marketing_setting_id)}
          />
        )
      )}
    </>
  );
};

const DashboardTileAdSettingsCard = ({
  label,
  onUploadImg,
  onUpdate,
  loading,
  title,
  link,
  active,
  file_data,
  type,
  error,
}) => {
  const _createEventHandler = React.useCallback((property, remoteUpdate=false, options={}) => {
    return e => {
      let val = '';
      let err = '';
      if (options && options.val) {
        if (typeof options.val == 'function') {
          val = options.val();
        } else {
          val = options.val;
        }
      } else if (options && options.type) {
        if (options.type == 'select') {
          val = e.value;
        }
      } else if (options && options.isFile) {
        val = e.target && e.target.files
          ? e.target.files[0].name
          : (e.target  ? e.target.value : e);
      } else {
        val = e.target ? e.target.value : (e.value || e);
      }

      if (options) {
        if (options.checks) {
          if (options.checks(val) === false) {
            err = options.errorMsg || 'Invalid value';
          }
        }

        if (options.filterVal) {
          val = options.filterVal(val);
        }
      }

      const payload = options.additionalState ?? {};
      payload.update_remote = remoteUpdate;

      if (err) {
        payload.error = options.errorMsg || 'Invalid value';
      } else {
        payload[property] = val;
      }
      
      onUpdate(payload);
    };
  }, [onUpdate]);

  const _onChangeTitle = _createEventHandler('title', false);
  const _onChangeLink = _createEventHandler('link', false);
  const _onChangeContent = _createEventHandler('content', false, {
    isFile: checkTypeIsFile(type)
  });

  const _onUpdateTitle = _createEventHandler('title', true, {
    checks: checkContainsJS,
    filterVal: stripScript,
  });
  const _onUpdateLink = _createEventHandler('link', true, {
    checks: (link) => isSafeUrl(link),
    errorMsg: 'Url must start with http:// or https://',
  });
  const _onUpdateActive = _createEventHandler('active', true, {
    val: () => active == 1 ? 0 : 1,
    checks: (val) => {
      if (val == 0 || file_data) { return true; }
      return false;
    },
    errorMsg: 'Upload an image in order to activate it.'
  });

  const isActive = active == 1;

  return (
    <Box padded>
      <Row><Col padded xs><H5>{label}</H5></Col></Row>
      {!!error && <Row><Col padded xs><p style={ERROR_STYLE}>{error}</p></Col></Row>}
      <Row>
        <Col padded xs>
          <div>
            <LabeledInput
              label="Title"
              value={title}
              onChange={_onChangeTitle}
              onBlur={_onUpdateTitle}
              onFocus={(e) => e.target.select()}
            />
          </div>
          <div>
            <LabeledInput
              label="Link"
              value={link}
              onChange={_onChangeLink}
              onBlur={_onUpdateLink}
              onFocus={(e) => e.target.select()}
            />
          </div>
          <Toggle style={{width: 110}} stretch={false}>
            <ToggleLink
              selected={isActive}
              onClick={_onUpdateActive}
            >Active</ToggleLink>
            <ToggleLink
              selected={!isActive}
              onClick={_onUpdateActive}
            >Disabled</ToggleLink>
          </Toggle>
        </Col>
        <Col padded xs>
          <div>
            <Dropzone
                onDrop={onUploadImg}
                multiple={false}
                accept="image/*"
                style={getDropzoneStyle(file_data)}
            >
              <>{loading
                ? <div className="small-12 text-center"><br /><img src="/images/gears.gif" /><br /></div>
                : file_data
                  ? <div style={{ textAlign: 'center' }}>
                      <Img
                        src={getImageSrc(file_data, 'original')}
                        style={{ maxHeight: IMG_SIZE.h }}
                        width={IMG_SIZE.w}
                        target="_blank"
                      />
                    </div>
                  : <div style={{ textAlign: 'center', }}>
                      Upload Image
                      <span style={{color: '#A4ABAE',}}>
                        <br/><span style={{fontWeight: 'bold'}}>Image Dimensions</span>
                        <br/>Width: {IMG_SIZE.w}px, Height: {IMG_SIZE.h}px
                      </span>
                    </div>
              }</>
            </Dropzone>
          </div>
        </Col>
      </Row>
    </Box>
  );
};
