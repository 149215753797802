import * as actions from '../actions/marketing_settings';
import * as file_actions from '../actions/file';

export const setting_reducer = (state={}, action) => {
    switch (action.type) {
        case actions.ADD_MARKETING_SETTING_REQUEST:
            return { ...state };
    }
    return state;
};

export const settings_reducer = (state = {}, action) => {
    let s_idx, temp;

    if (action && action.payload && action.payload.setting_id) {
        s_idx = state.data.findIndex(v => v.marketing_setting_id == action.payload.setting_id);
    } else if (action && action.payload && action.payload.setting_name) {
        if (action.payload.setting_name.indexOf('USER-POPUP') != -1) {
            s_idx = state.data.findIndex(v => v.setting_name == action.payload.setting_name);
        }
    }

    switch (action.type) {
        case actions.LOAD_MARKETING_SETTINGS_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case actions.LOAD_MARKETING_SETTINGS_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                data: action.payload.value,
            };
        case actions.LOAD_MARKETING_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
// ===========
        case file_actions.UPLOAD_FILE_REQUEST:
            if (s_idx == null || s_idx == undefined || s_idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, s_idx),
                    {
                        ...state.data[s_idx],
                        loading: true,
                        error: '',
                    },
                    ...state.data.slice(s_idx+1),
                ],
            };
        case file_actions.UPLOAD_FILE_SUCCESS:
            if (s_idx == null || s_idx == undefined || s_idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, s_idx),
                    {
                        ...state.data[s_idx],
                        loading: false,
                        error: '',
                    },
                    ...state.data.slice(s_idx+1),
                ],
            };
        case file_actions.UPLOAD_FILE_FAILURE:
            if (s_idx == null || s_idx == undefined || s_idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, s_idx),
                    {
                        ...state.data[s_idx],
                        loading: false,
                        error: 'Unable to upload file',
                    },
                    ...state.data.slice(s_idx+1),
                ],
            };
// ===========
        case actions.ADD_MARKETING_SETTING_REQUEST:
            return {
                ...state,
            };
        case actions.ADD_MARKETING_SETTING_SUCCESS:
            if (action.payload.value.add_to_top === true) {
                temp = [
                    {
                        ...action.payload.value,
                        error: '',
                        loading: false,
                    },
                    ...state.data,
                ];
            } else {
                temp = [
                    ...state.data,
                    {
                        ...action.payload.value,
                        error: '',
                        loading: false,
                    },
                ];
            }

            return {
                ...state,
                data: temp,
            };
        case actions.ADD_MARKETING_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
// ===========
        case actions.UPDATE_MARKETING_SETTING_REQUEST:
            if (s_idx == null || s_idx == undefined || s_idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, s_idx),
                    {
                        ...state.data[s_idx],
                        loading: true,
                        error: '',
                    },
                    ...state.data.slice(s_idx+1),
                ],
            };
        case actions.UPDATE_MARKETING_SETTING_STATE:
        case actions.UPDATE_MARKETING_SETTING_SUCCESS:
            if (s_idx == null || s_idx == undefined || s_idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, s_idx),
                    {
                        ...state.data[s_idx],
                        ...action.payload.value,
                        error: '',
                        loading: false,
                    },
                    ...state.data.slice(s_idx+1),
                ],
            };
        case actions.UPDATE_MARKETING_SETTING_FAILURE:
            if (s_idx == null || s_idx == undefined || s_idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, s_idx),
                    {
                        ...state.data[s_idx],
                        error: action.payload.error,
                        loading: false,
                    },
                    ...state.data.slice(s_idx+1),
                ],
            };
// ===========
        case actions.DELETE_MARKETING_SETTING_REQUEST:
            if (s_idx == null || s_idx == undefined || s_idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, s_idx),
                    {
                        ...state.data[s_idx],
                        loading: true,
                        error: '',
                    },
                    ...state.data.slice(s_idx+1),
                ],
            };
        case actions.DELETE_MARKETING_SETTING_SUCCESS:
            return {
                ...state,
                data: state.data.filter(v => v.marketing_setting_id != action.payload.setting_id),
            };
        case actions.DELETE_MARKETING_SETTING_FAILURE:
            return {...state, };
// ===========
        case actions.UPDATE_USER_POPUP_SETTING_REQUEST:
            if (s_idx == null || s_idx == undefined || s_idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, s_idx),
                    {
                        ...state.data[s_idx],
                        loading: true,
                        error: '',
                    },
                    ...state.data.slice(s_idx+1),
                ],
            };
        case actions.UPDATE_USER_POPUP_SETTING_STATE:
        case actions.UPDATE_USER_POPUP_SETTING_SUCCESS:
            if (s_idx == null || s_idx == undefined || s_idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, s_idx),
                    {
                        ...state.data[s_idx],
                        ...action.payload.value,
                        error: '',
                        loading: false,
                    },
                    ...state.data.slice(s_idx+1),
                ],
            };
        case actions.UPDATE_USER_POPUP_SETTING_FAILURE:
            return {...state, };
// ===========
    }

    return state;
};

// Whats new posts Reducer
export const whatsnew_posts_reducer = (state = {}, action) => {
    // idx=parent_id, c_idx=child_id
    let idx, c_idx, temp;

    if (action && action.payload) {
        if (action.payload.value && action.payload.value.parent_id) {
            idx = state.data.findIndex(v => v.whatsnew_post_id == action.payload.value.parent_id);
            if (action.payload.whatsnew_post_id) {
                c_idx = state.data[idx].children.findIndex(v => v.whatsnew_post_id == action.payload.whatsnew_post_id);
            }
        } else {
            if (action.payload.whatsnew_post_id) {
                idx = state.data.findIndex(v => v.whatsnew_post_id == action.payload.whatsnew_post_id);
            }
        }
    }

    switch (action.type) {
        case actions.LOAD_WHATSNEW_POSTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case actions.LOAD_WHATSNEW_POSTS_SUCCESS:
            return {
                ...state,
                error: '',
                loading: false,
                data: action.payload.value,
            };
        case actions.LOAD_WHATSNEW_POSTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
// ===========
        case actions.ADD_WHATSNEW_POST_REQUEST:
            return {
                ...state,
            };
        case actions.ADD_WHATSNEW_POST_SUCCESS:
            // Update Children
            if (idx >= 0) {
                temp = [
                    ...state.data.slice(0, idx),
                    {
                        ...state.data[idx],
                        children: [
                            ...(state.data[idx].children || []),
                            action.payload.value,
                        ]
                    },
                    ...state.data.slice(idx+1),
                ];
            } else {
                temp = [
                    {
                        ...action.payload.value,
                        error: '',
                        loading: false,
                    },
                    ...state.data,
                ];
            }

            return {
                ...state,
                data: temp,
            };
        case actions.ADD_WHATSNEW_POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
// ===========
        case actions.UPDATE_WHATSNEW_POST_REQUEST:
            if (idx == null || idx == undefined || idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, idx),
                    {
                        ...state.data[idx],
                        loading: true,
                        error: '',
                    },
                    ...state.data.slice(idx+1),
                ],
            };
        case actions.UPDATE_WHATSNEW_POST_STATE:
        case actions.UPDATE_WHATSNEW_POST_SUCCESS:
            if (idx == null || idx == undefined || idx < 0) { return state; }
            // Update Children
            if (c_idx >= 0) {
                temp = {
                    ...state.data[idx],
                    children: [
                        ...state.data[idx].children.slice(0, c_idx),
                        {
                            ...state.data[idx].children[c_idx],
                            ...action.payload.value,
                        },
                        ...state.data[idx].children.slice(c_idx+1),
                    ],
                    loading: false,
                    error: '',
                };
            } else {
                temp = {
                    ...state.data[idx],
                    ...action.payload.value,
                    error: '',
                    loading: false,
                };
            }

            return {
                ...state,
                data: [
                    ...state.data.slice(0, idx),
                    temp,
                    ...state.data.slice(idx+1),
                ],
            };
        case actions.UPDATE_WHATSNEW_POST_FAILURE:
            if (idx == null || idx == undefined || idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, idx),
                    {
                        ...state.data[idx],
                        error: action.payload.error,
                        loading: false,
                    },
                    ...state.data.slice(idx+1),
                ],
            };
// ===========
        case actions.DELETE_WHATSNEW_POST_REQUEST:
            if (idx == null || idx == undefined || idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, idx),
                    {
                        ...state.data[idx],
                        loading: true,
                        error: '',
                    },
                    ...state.data.slice(idx+1),
                ],
            };
        case actions.DELETE_WHATSNEW_POST_SUCCESS:
            if (idx == null || idx == undefined || idx < 0) { return state; }
            // Update Children
            if (c_idx >= 0) {
                temp = [
                    ...state.data.slice(0, idx),
                    {
                        ...state.data[idx],
                        children: state.data[idx].children.filter(v => v.whatsnew_post_id != action.payload.whatsnew_post_id),
                        loading: false,
                        error: '',
                    },
                    ...state.data.slice(idx+1),
                ];
            } else {
                temp = state.data.filter(v => v.whatsnew_post_id != action.payload.whatsnew_post_id);
            }

            return { ...state, data: temp, };
        case actions.DELETE_MARKETING_SETTING_FAILURE:
            if (idx == null || idx == undefined || idx < 0) { return state; }
            return {
                ...state,
                data: [
                    ...state.data.slice(0, idx),
                    {
                        ...state.data[idx],
                        loading: false,
                        error: action.payload.error || 'Unable to delete',
                    },
                    ...state.data.slice(idx+1),
                ],
            };
    }

    return state;
};

