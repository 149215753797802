import React, { Component } from 'react';
import { connect } from 'react-redux';
import { oauth } from '../utils';
import Header from './Header';
import { Tabs, Background } from '@commonsku/styles';
import MainSection from './MainSection';
import ErrorBoundary from '../components/ErrorBoundary';
import Overlay from '../components/Overlay';
import {
  HomePageSettings,
  LoginPageSettings,
  ConnectedUserPopupSettings,
  WhatsNewSettings,
  DashboardTileAdSettings,
  DASHBOARD_TILE_ADS,
} from '../components/marketing-settings';
import { getPopups } from '../selectors';
import { createNewFeaturePopup } from '../actions/popup';
import { createUploadFile, createDeleteFile } from '../actions/file';
import createPopup from '../popup-factory';
import {
  createLoadMarketingSettings,
  createAddMarketingSetting,
  createUpdateMarketingSetting,
  createUpdateMarketingSettingState,
  createDeleteMarketingSetting,
  createUpdateUserPopupSetting,
  createErrorUpdateMarketingSetting,
  createDeleteMarketingSettingRelatedFiles,
  createDeleteWhatsnewPostRelatedFiles,
  createErrorUpdateWhatsnewPost,

  createLoadWhatsnewPosts,
  createAddWhatsnewPost,
  createUpdateWhatsnewPost,
  createDeleteWhatsnewPost,
  createUpdateWhatsnewPostState,
} from '../actions/marketing_settings';
import { reducers } from '../store/configureMarketingSettingsStore';
import withReducers from '../store/withReducers';

import '../../scss/css/marketing_settings.css';

class MarketingSettingsApp extends Component {

  audiences = [
    { value: 'SUPPLIER', label: 'Suppliers' },
    { value: 'TENANT', label: 'Distributors' },
    { value: 'ALL', label: 'All' },
  ];

  findAudience = (value) => {
    return this.audiences
        && Array.isArray(this.audiences)
        && this.audiences.length > 0
        && this.audiences.filter(v => v.value == value)[0];
  };

  componentDidMount() {
    const { user_id, onCreateNewFeaturePopup } = this.props;

    document.getElementsByTagName('body')[0].className = '';
    document.getElementsByTagName('html')[0].className = '';

    oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
      if (json.show_popup == 1) {
        onCreateNewFeaturePopup();
      }
    });
  }

  renderTabs() {
    const {
      settings,
      addSetting,
      updateSetting,
      deleteSetting,
      onUploadImg,
      onDeleteFile,
      loading,
      updateUserPopupSetting,
      display_loading,

      whatsnew_posts,
      whatsnew_posts_loading,
      addWhatsnewPost,
      updateWhatsnewPost,
      deleteWhatsnewPost,
    } = this.props;

    const common_props = {
      audiences: this.audiences,
      findAudience: this.findAudience,
      settings,
      addSetting,
      updateSetting,
      deleteSetting,
      onUploadImg,
      onDeleteFile,
      loading,
      display_loading,
    };

    const tabs = [
      {
        key: 'homepage',
        label: 'Homepage Settings',
        content: <Background padded fillWindow>
          <HomePageSettings {...common_props} setting_name={'HOMEPAGE-AD'} />
        </Background>,
        onClick: () => {
          this.props.loadSettings('HOMEPAGE-AD', {reverse: true});
        },
      },
      {
        key: 'login_page',
        label: 'Login Page Settings',
        content: <Background padded fillWindow>
            <LoginPageSettings {...common_props} setting_name={'LOGINPAGE-AD'} />
          </Background>,
        onClick: () => {
          this.props.loadSettings('LOGINPAGE-AD');
        },
      },
      {
        key: 'user_popup',
        label: 'User Popup',
        content: <Background padded fillWindow>
            <ConnectedUserPopupSettings {...common_props} updateSetting={updateUserPopupSetting} setting_name={'USER-POPUP'} />
          </Background>,
        onClick: () => {
          this.props.loadSettings('USER-POPUP');
        },
      },
      {
        key: 'whats_new',
        label: 'Whats New',
        content: <Background padded fillWindow>
            <WhatsNewSettings
              posts={whatsnew_posts}
              loading={whatsnew_posts_loading}
              display_loading={display_loading}
              onUploadImg={onUploadImg}
              onDeleteFile={onDeleteFile}
              audiences={this.audiences}
              findAudience={this.findAudience}
              onAdd={addWhatsnewPost}
              onUpdate={updateWhatsnewPost}
              onDelete={deleteWhatsnewPost}
            />
          </Background>,
        onClick: () => {
          this.props.loadWhatsnewPosts();
        },
      },
      {
        key: 'dashbard_tile_ad',
        label: 'Dashboard Tile Ads',
        content: <Background padded fillWindow>
          <DashboardTileAdSettings
            {...common_props}
          />
        </Background>,
        onClick: () => {
          this.props.loadSettings(Object.keys(DASHBOARD_TILE_ADS));
	},
      }
    ];
    return <Tabs tabs={tabs} />;
  }

  render() {
    const { popups } = this.props;

    return (
      <ErrorBoundary>
        <div className="resku">
          <Header>
            <div className="columns">
              <h4 style={{ display: 'inline-block' }}>Marketing Settings</h4>
            </div>
          </Header>
        </div>
        <div>
          <div className="resku"><MainSection /></div>
          <div ref={ref => this.root = ref} className="main-content page" style={{ marginTop: '0rem', }}>
            {this.renderTabs()}
          </div>
          <div className="resku">
            {popups ? popups.map((p, idx) => createPopup(p, idx, this.props)) : null}
          </div>
        </div>
        <div className="resku">
          <Overlay popups={popups} />
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  popups: getPopups(state),
  user_id: state.identity.user_id,
  settings: state.entities.marketing_settings,
  whatsnew_posts: state.entities.whatsnew_posts,
  whatsnew_posts_loading: state.entities.whatsnew_posts ? state.entities.whatsnew_posts.loading : true,
  loading: state.entities.marketing_settings ? state.entities.marketing_settings.loading : true,
  display_loading: state.display.loading === true,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    },
    loadSettings: (setting_name, data={}) => {
      if (setting_name) {
        return dispatch(createLoadMarketingSettings({ setting_name, ...data }));
      }
    },
    addSetting: ({ setting_name, ...data }) => {
      if (setting_name) {
        return dispatch(createAddMarketingSetting({ setting_name, ...data }));
      }
    },
    updateSetting: (setting_id, data) => {
      if (setting_id) {
        return dispatch(createUpdateMarketingSetting(setting_id, data));
      }
    },
    deleteSetting: (setting_id) => {
      if (setting_id) {
        return dispatch(createDeleteMarketingSetting(setting_id));
      }
    },
    updateUserPopupSetting: (setting_name, {update_remote=true, active=0, content=''}) => {
      return dispatch(createUpdateUserPopupSetting(setting_name, {update_remote, active, content}));
    },
    onUploadImg: (id, parent_type='MARKETING-SETTING', extra_data={}) => files => {
      if (!files || (Array.isArray(files) && files.length == 0)) {
        if (parent_type.indexOf('MARKETING-SETTING') != -1) {
          return dispatch(createErrorUpdateMarketingSetting(id, 'Cannot Upload. Only Image file types accepted.'));
        } else if (parent_type.indexOf('WHATSNEW-POST') != -1) {
          return dispatch(createErrorUpdateWhatsnewPost(id, 'Cannot Upload. Only Image file types accepted.', extra_data));
        }
      }
      const resp = dispatch(createUploadFile(id, parent_type, files[0]));
      Promise.resolve(resp)
        .then(v => {
          if (parent_type.indexOf('MARKETING-SETTING') != -1) {
            dispatch(createDeleteMarketingSettingRelatedFiles(id, v.payload.data.file_id));
            dispatch(createUpdateMarketingSettingState(id, { file_data: v.payload.data }));
          } else if (parent_type.indexOf('WHATSNEW-POST') != -1) {
            dispatch(createDeleteWhatsnewPostRelatedFiles(id, v.payload.data.file_id));
            dispatch(createUpdateWhatsnewPostState(id, { file_data: v.payload.data, ...extra_data }));
          }
        })
        .catch(err => {
          // console.error(err)
        });
    },
    loadWhatsnewPosts: (data={audience: ''}) => {
      return dispatch(createLoadWhatsnewPosts(data));
    },
    addWhatsnewPost: (data={}) => {
      return dispatch(createAddWhatsnewPost(data));
    },
    updateWhatsnewPost: (id, data={}) => {
      return dispatch(createUpdateWhatsnewPost(id, data));
    },
    deleteWhatsnewPost: (id, data={}) => {
      return dispatch(createDeleteWhatsnewPost(id, data));
    },
    onDeleteFile: (id, parent_type='MARKETING-SETTING', file_id) => {
      if (!file_id || !id || !parent_type) { return; }

      const resp = dispatch(createDeleteFile(file_id));
      Promise.resolve(resp)
      .then(v => {
        if (parent_type.indexOf('MARKETING-SETTING') != -1) {
          dispatch(createUpdateMarketingSettingState(id, { file_data: null }));
        } else if (parent_type.indexOf('WHATSNEW-POST') != -1) {
          dispatch(createUpdateWhatsnewPostState(id, { file_data: null }));
        }
      })
      .catch(err => {
        // console.error(err)
      });
    }
  };
};

export default withReducers(
  connect(mapStateToProps, mapDispatchToProps)(MarketingSettingsApp),
  reducers, true
);
