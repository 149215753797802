import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import api from '../middleware/api';
// import rootReducer from '../reducers/core'
import displayReducer from '../reducers/display';
import tempReducer from '../reducers/temp';
import { settings_reducer, whatsnew_posts_reducer } from '../reducers/marketing_settings';

import { window } from '../global';

const defaultDropdownState = window.initialState && window.initialState.dropdowns ? window.initialState.dropdowns : {};
const dropdowns = (state = defaultDropdownState, action) => {
  return state;
};

const defaultIdentityState = window.initialState && window.initialState.identity ? window.initialState.identity : {};
const identityReducer = (state = defaultIdentityState, action) => {
  return state;
};

const defaultEntitiesState = window.initialState && window.initialState.entities ? window.initialState.entities : {};
const entitiesReducer = (state = defaultEntitiesState, action) => {
  const new_state = Object.assign({}, state, {
    marketing_settings: settings_reducer(state.marketing_settings ? state.marketing_settings : [], action),
    whatsnew_posts: whatsnew_posts_reducer(state.whatsnew_posts ? state.whatsnew_posts : [], action),
  });

  return new_state;
};

export const reducers = {
  display: displayReducer,
  temp: tempReducer,
  dropdowns: dropdowns,
  entities: entitiesReducer,
  identity: identityReducer,
};

export default function configureMarketingSettingsStore(initialState) {
  const store = createStore(
    combineReducers(reducers),
    initialState,
    compose(
      applyMiddleware(thunk, api),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );

  return store;
}
