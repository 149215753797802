import { useState } from 'react';
import { filter, toLower } from 'lodash';

const sameAudience = (a1, a2) => {
    a1 = toLower(a1);
    a2 = toLower(a2);
    return a1 === 'all' || a2 === 'all' || a1 === a2;
};

const filterByAudience = (data, audience, isActive) => {
    return filter(data, (v) => {
        return (isActive === -1 || parseInt(v.active) === isActive) && sameAudience(audience, v.audience);
    });
};

export const useFilterAudience = (data, initial={active: -1, audience: 'ALL'}) => {
    const [ active, setActive ] = useState(initial.active);
    const [ audience, setAudience ] = useState(initial.audience);
    const filteredData = filterByAudience(data, audience, active);
    return [ setActive, setAudience, audience, active, filteredData ];
};
